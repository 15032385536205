<template>
  <ion-page></ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { IonPage } from '@ionic/vue';

export default defineComponent({
  name: 'LogOut',
  components: {
    IonPage
  },
  created() {
    const store = useStore();
    // const router = useRoute();

    store.dispatch('auth/logout');
    this.$router.replace('/login');
  }
});
</script>
